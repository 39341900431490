<template>
  <PageWithHeader>
    <template #title><t path="news_2">News</t></template>
    <div class="df-col fill">
      <div class="p-16">
        <p class="f-bold f-lg mb-16">{{detail.articleTitle}}</p>
        <div class="df-between df-middle">
          <div>
            <Pic v-if="detail.authorUrl" :src="detail.authorUrl" height="16" />
          </div>
          <Time class="t-right c-tip f-sm"
            format="MM-dd-yy HH:mm:ss"
            :time="detail.createTime"
          />
        </div>
        <div class="f-sm mt-16 df-middle" v-if="products && products.length">
          <router-link
            v-for="item in products" :key="item" :to="`/symbol/${item.trim()}`"
            class="btn sm round mr-16 px-6"
            style="height: 20px; line-height: 20px;"
          >
            {{item}}
          </router-link>
        </div>
      </div>
      <div class="px-16 advice-line pb-16"><Line /></div>
      <div class="px-8 flex-1">
        <iframe class="frame" :src="detail.articleUrl" />
      </div>
    </div>
  </PageWithHeader>
</template>

<script lang="ts">
import { ref, defineComponent } from 'vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Time from '@/components/Time.vue'
import Line from '@/components/Line.vue'
import { useRouter } from 'vue-router'
import { readDetail, NewsSchema } from '@/modules/community/news/news.api'
import * as R from 'ramda'
import Pic from 'common/Pic.vue'

export default defineComponent({
  name: 'Detail',
  components: { Pic, Line, PageWithHeader, Time },
  props: {
    id: String,
  },
  setup (props) {
    const detail = ref<NewsSchema>({} as NewsSchema)
    const products = ref<string[]>([])

    const fail = () => {
      useRouter().back()
    }

    const updateNews = (resp: NewsSchema) => {
      detail.value = resp
      const arr = resp?.product?.split(',')
      products.value = R.filter(v => Boolean(v), arr)
    }

    if (props.id) {
      readDetail({ articleId: props.id }).then(updateNews).catch(fail)
    }

    return {
      detail,
      products,
    }
  },
})
</script>
<style scoped lang="scss">
  .advice-line .line::after {
    border-color: var(--color-grey);
  }

  .frame {
    border: none;
    width: 100%;
    min-height: 100%;
  }
</style>
